import { Button, Menu, Spacer, Tooltip } from '@vapor/ui';
import { styled } from 'buttered';
import capitalize from 'lodash/capitalize';
import { useRouter } from 'next/router';
import { MoreVertical, PieChart } from 'react-feather';
import useToday from 'use-today';
import { formatNumber } from '../../lib/formatNumber';
import { ICalendar } from '../../state/interfaces/calendar';
import { IProgress } from '../../state/interfaces/progress';

let Wrapper = styled('div')`
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 12px;
  border: var(--vapor-border);
  background: var(--vapor-background);
  text-align: left;
  min-height: 230px;
  transition: all 0.3s;
  color: var(--vapor-foreground);
  overflow: hidden;

  &:hover,
  &:focus {
    border: var(--vapor-border-primary);
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.12);
  }
`;

let Header = styled('header')`
  width: 100%;
  padding: 20px;

  nav {
    display: flex;
    gap: 10px;
    margin-bottom: 4px;
  }

  h3 {
    font-weight: 600;
    font-size: 28px;
  }
`;

let ProgressWrapper = styled('footer')`
  padding: 17px 20px 20px 20px;
  border-top: var(--vapor-border);
  background: var(--vapor-accent-1);

  & > p {
    font-size: 12px;
    font-weight: 600;
    opacity: 0.6;
    margin-bottom: 5px;
  }
`;

let Progress = styled('div')`
  height: 7px;
  background: var(--vapor-accent-3);
  border-radius: 10px;
  width: 100%;

  & > div {
    background: var(--vapor-primary);
    border-radius: 10px;
    height: 7px;
  }
`;

let Type = styled('p')`
  border-radius: 3px;
  color: var(--vapor-background);
  padding: 3px 5px;
  font-size: 13px;
  font-weight: 600;
  width: fit-content;
  margin-bottom: 6px;
`;

export let Calendar = ({
  calendar,
  hasAnalytics,
  progress,
  isStartOfWeek
}: {
  hasAnalytics: boolean;
  calendar: ICalendar;
  progress?: IProgress;
  isStartOfWeek?: boolean;
}) => {
  let router = useRouter();
  let today = useToday();

  let percent = progress
    ? progress.total == 0
      ? 100
      : Math.round((progress.done / progress.total) * 100)
    : 0;

  return (
    <Wrapper>
      <Header>
        <nav>
          <main>
            <Type
              style={{
                background: calendar.isBoard ? 'var(--vapor-orange)' : 'var(--vapor-primary)'
              }}
            >
              <p>{calendar.isBoard ? 'Board' : 'Calendar'}</p>
            </Type>
          </main>

          <Spacer />

          <div
            onClick={e => {
              e.stopPropagation();
              e.preventDefault();
            }}
            style={{ display: 'flex', gap: 15 }}
          >
            {calendar.isCalendar && hasAnalytics && (
              <Button
                variant="secondary"
                size="small"
                onClick={e => {
                  e.preventDefault();
                  e.stopPropagation();

                  router.push(`/analytics?calendarId=${calendar.id}`);
                }}
                icon={<PieChart />}
              >
                Analytics
              </Button>
            )}

            <Menu
              label="Menu"
              action={id => {
                if (id == 'open') window.location.href = `/view/${calendar.id}`;
                if (id == 'settings')
                  router.push(`/team/${calendar.teamId}/calendar/${calendar.id}`);
                if (id == 'team') router.push(`/team/${calendar.teamId}`);
                if (id == 'analytics') router.push(`/analytics?calendarId=${calendar.id}`);
                if (id == 'delete')
                  router.push(
                    `/team/${calendar.teamId}/calendar/${calendar.id}?action=delete`
                  );
              }}
              items={
                [
                  {
                    id: 'open',
                    type: 'item',
                    component: `Open ${calendar.isBoard ? 'Board' : 'Calendar'}`
                  },
                  {
                    id: 'team',
                    type: 'item',
                    component: `Team Settings`
                  },
                  {
                    id: 'settings',
                    type: 'item',
                    component: `${calendar.isBoard ? 'Board' : 'Calendar'} Settings`
                  },
                  calendar.isCalendar
                    ? {
                        id: 'analytics',
                        type: 'item',
                        component: `Open Analytics`
                      }
                    : false,
                  {
                    id: 'delete',
                    type: 'item',
                    component: `Delete ${calendar.isBoard ? 'Board' : 'Calendar'}`
                  }
                ].filter(Boolean) as any
              }
            >
              {({ attrs, ref }) => (
                <Button
                  variant="secondary"
                  size="small"
                  {...attrs}
                  onClick={e => {
                    e.preventDefault();
                    e.stopPropagation();

                    if (attrs.onClick) attrs.onClick(e);
                  }}
                  ref={ref}
                  title="Open Menu"
                  icon={<MoreVertical />}
                />
              )}
            </Menu>
          </div>
        </nav>

        <h3>{calendar.name}</h3>
      </Header>

      <Spacer />

      <Tooltip
        delay={500}
        timeout={10}
        content={
          progress
            ? `${capitalize(formatNumber(progress.done).toString())} of ${formatNumber(
                progress.total
              )} tasks done${calendar.isBoard ? '' : ' today'}`
            : ''
        }
      >
        {({ attrs, ref }) => (
          <ProgressWrapper {...attrs} ref={ref}>
            <p>{today.toLocaleDateString()}</p>
            <Progress>
              <div
                style={{
                  width: `${percent}%`,
                  background: calendar.isBoard ? 'var(--vapor-orange)' : 'var(--vapor-primary)'
                }}
              />
            </Progress>
          </ProgressWrapper>
        )}
      </Tooltip>
    </Wrapper>
  );
};
