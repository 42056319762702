import { styled } from 'buttered';
import React from 'react';

let Wrapper = styled('div')`
  max-width: 720px;
  margin: 100px auto 0px auto;
  padding: 0px 15px;

  @media screen and (max-width: 800px) {
    margin: 30px auto 0px auto;
  }
`;

export let Inner = ({ children, width }: { children: React.ReactNode; width?: number }) => {
  return <Wrapper style={{ maxWidth: width }}>{children}</Wrapper>;
};
