import * as Sentry from '@sentry/nextjs';
import {
  Avatar,
  Badge,
  Button,
  Input,
  Menu,
  Modal,
  Spacer,
  Spinner,
  Text,
  toast,
  VerticalCenter
} from '@vapor/ui';
import createLocalstorageState from '@weekrise/use-localstorage';
import { styled } from 'buttered';
import { differenceInDays, startOfWeek } from 'date-fns';
import { useFormik } from 'formik';
import Link from 'next/link';
import { useEffect, useMemo, useState } from 'react';
import { Calendar as CalendarIcon, Grid as GridIcon, Plus, Settings, X } from 'react-feather';
import useToday from 'use-today';
import * as Yup from 'yup';
import { useQuery } from '../../hooks/useQuery';
import { Calendar } from '../components/compound/calendar';
import { Inner } from '../components/inner';
import { upgradeModal } from '../components/upgrade';
import { Layout } from '../layout';
import { useBoot, useProgress } from '../state/useBoot';

let Header = styled('header')`
  margin-bottom: 20px;
  transition: all 0.2s;
`;

let Holiday = styled('div')`
  height: 40px;
  background: var(--vapor-green);
  color: white;
  border-radius: 10px;
  padding: 0px 18px;
  line-height: 40px;
  width: fit-content;
  margin-bottom: 10px;
  font-weight: 600;
  font-size: 16px;
`;

let Grid = styled('div')`
  display: grid;
  grid-template-columns: calc(100% - 300px) 270px;
  gap: 30px;

  @media screen and (max-width: 900px) {
    gap: 0px;
    grid-template-columns: 100%;
  }
`;

let Sidebar = styled('aside')`
  text-align: center;

  .center {
    margin: 0px auto;
    width: fit-content;
  }

  h1 {
    font-size: 26px;
  }

  @media screen and (max-width: 900px) {
    display: none;
  }
`;

let Calendars = styled('div')`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;

  @media screen and (max-width: 800px) {
    grid-template-columns: 100%;
  }
`;

let TeamHeader = styled('header')`
  display: flex;
  margin-bottom: 16px;
  gap: 10px;

  @media screen and (max-width: 800px) {
    flex-direction: column;
  }

  & > div {
    display: flex;
  }
`;

let LoadingWrapper = styled('div')`
  margin: calc(50vh - 120px) auto 0px auto;
  width: fit-content;
`;

let NewHint = styled('div')`
  padding: 30px;
  font-size: 1.3em;
  color: var(--vapor-primary-text);
  background: var(--vapor-primary);
  border-radius: 15px;
  margin-bottom: 26px;
  display: grid;
  grid-template-columns: 1fr 30px;

  div {
    h1 {
      font-size: 1.4em;
      font-weight: 600;
      margin-bottom: 6px;
    }
  }
`;

let CalendarSchema = Yup.object().shape({
  name: Yup.string()
    .max(100, `A name can't be longer than 100 characters.`)
    .required('Looks like you forgot the name.')
});

let useNewHint = createLocalstorageState('home/new-hint/1', true);
let useGCalHint = createLocalstorageState('home/gcal-hint/1', true);

export let HomeHeader = ({ hideDescription }: { hideDescription?: boolean }) => {
  let { user } = useBoot();

  let [holidays, setHolidays] = useState([]);
  useEffect(() => {
    let iv = setInterval(() => {
      if (!(window as any).getCurrentHolidays) return;

      clearInterval(iv);
      (window as any).getCurrentHolidays().then(setHolidays);
    }, 50);
  }, []);

  return (
    <Header style={!user ? { opacity: 0 } : {}}>
      {holidays.length > 0 && (
        <Holiday>
          <p>{holidays[0].name}</p>
        </Holiday>
      )}

      <Text size={30} weight={700} as="h1">
        Hey, {user?.name}!
      </Text>

      {!hideDescription && (
        <>
          <Spacer height="5px" />

          <Text size={20} weight={600} as="p" style={{ opacity: 0.5 }}>
            Choose a board or calendar to continue.
          </Text>
        </>
      )}
    </Header>
  );
};

export default function Home() {
  let { teams, user, createCalendar, error, loading } = useBoot();
  let { progress } = useProgress();
  let [gcalHint, setGCalHint] = useGCalHint();

  let teamId = useQuery('teamId');
  let action = useQuery('action');

  let [createCalendarModalOpen, setCreateCalendarModalOpen] = useState(false);
  let [createCalendarType, setCreateCalendarType] = useState<'calendar' | 'board'>();
  let [currentTeamId, setCurrentTeamId] = useState<string>();
  let [creating, setCreating] = useState(false);

  let today = useToday();

  useEffect(() => {
    if (teamId) setCurrentTeamId(teamId);
  }, [currentTeamId]);

  useEffect(() => {
    if (action == 'create.calendar') {
      setCreateCalendarType('calendar');
      setCreateCalendarModalOpen(true);
    }

    if (action == 'create.board') {
      setCreateCalendarType('board');
      setCreateCalendarModalOpen(true);
    }
  }, [action]);

  let createCalendarForm = useFormik({
    initialValues: {
      name: ''
    },
    onSubmit: async values => {
      if (!createCalendarType || !currentTeamId) return;

      setCreating(true);
      createCalendar(currentTeamId, {
        name: values.name,
        type: createCalendarType
      })
        .then(cal => {
          window.location.replace(`/view/${cal.id}`);
        })
        .catch(err => {
          if (err?.response?.data?.code == 'calendar_limit_reached') {
            setCreating(false);
            setCurrentTeamId(undefined);
            setCreateCalendarType(undefined);

            return upgradeModal({
              feature: 'unlimited calendars and boards',
              teamId: currentTeamId
            });
          }

          Sentry.captureException(err);
          toast.error(err?.response?.data?.message || 'Could not create calendar.');
        })
        .finally(() => {
          setCreating(false);
          setCurrentTeamId(undefined);
          setCreateCalendarType(undefined);
        });
    },
    validationSchema: CalendarSchema
  });

  useEffect(() => {
    if (!createCalendarType) return;
    createCalendarForm.resetForm();
  }, [createCalendarType]);

  let isStartOfWeek = useMemo(() => {
    if (!user) return false;

    let start = startOfWeek(today, { weekStartsOn: user.flags.startOfWeek as any });
    let diff = differenceInDays(today, start);

    if (diff <= 1 && diff >= 0) return true;
    return false;
  }, [user?.flags.startOfWeek]);

  return (
    <Layout pageId="home" layout="global">
      <Inner width={1170}>
        {loading ? (
          <LoadingWrapper>
            <Spinner size={30} />
          </LoadingWrapper>
        ) : (
          <>
            <Grid>
              <main>
                {gcalHint && !user?.integrations.google.enabled && (
                  <NewHint>
                    <div>
                      <h1>Weekrise + Google Calendar</h1>
                      <p>
                        Did you know that you can import your Google Calendar <br />
                        events into Weekrise?
                      </p>

                      <Spacer height={15} />

                      <Link href="/account/gcal">
                        <a>
                          <Button
                            style={{ width: 'fit-content' }}
                            as="div"
                            variant="primaryAlt"
                          >
                            Setup Google Calendar
                          </Button>
                        </a>
                      </Link>
                    </div>

                    <Button
                      onClick={() => setGCalHint(false)}
                      variant="primaryAlt"
                      icon={<X />}
                      title="Close"
                    />
                  </NewHint>
                )}

                <HomeHeader />

                {Array.isArray(teams) && teams.length == 0 && (
                  <p>
                    You don't have any teams yet. Get started by{' '}
                    <Link href="/team/create">
                      <a>creating one</a>
                    </Link>
                    .
                  </p>
                )}

                {(teams || []).map(
                  team =>
                    (!teamId || teamId == team.id) && (
                      <section key={team.id} style={{ marginTop: 30 }}>
                        <TeamHeader>
                          <div>
                            <Avatar
                              src={team.photoUrl + '?width=100'}
                              name={team.name}
                              size={40}
                            />

                            <Spacer width={10} />

                            <div style={{ display: 'flex', gap: 10 }}>
                              {team.plan.id != 'free' && (
                                <div style={{ display: 'flex' }}>
                                  <Badge>Pro</Badge>
                                </div>
                              )}

                              {team.disabled && (
                                <div style={{ display: 'flex' }}>
                                  <Badge color="red">Disabled</Badge>
                                </div>
                              )}

                              <Text
                                style={{
                                  fontSize: 25,
                                  fontWeight: 600,
                                  height: 40,
                                  lineHeight: '40px'
                                }}
                                as="h2"
                                truncate={3}
                              >
                                {team.name}
                              </Text>
                            </div>
                          </div>

                          <Spacer />

                          <div>
                            {!team.disabled && (
                              <div>
                                <VerticalCenter>
                                  <Menu
                                    label="Create Board or Calendar"
                                    action={id => {
                                      if (team.plan.id == 'free') {
                                        let calendarCount = team.calendars.filter(
                                          cal => cal.type == 'calendar'
                                        ).length;
                                        let boardCount = team.calendars.filter(
                                          cal => cal.type == 'board'
                                        ).length;

                                        if (
                                          (id == 'calendar' &&
                                            calendarCount >= team.plan.flags.calendars) ||
                                          (id == 'board' &&
                                            boardCount >= team.plan.flags.boards)
                                        ) {
                                          return upgradeModal({
                                            feature: 'unlimited calendars and boards',
                                            teamId: team.id
                                          });
                                        }
                                      }

                                      setCreateCalendarModalOpen(true);
                                      setCreateCalendarType(id as any);
                                      setCurrentTeamId(team.id);
                                    }}
                                    items={[
                                      {
                                        id: 'calendar',
                                        name: 'Calendar',
                                        icon: <CalendarIcon size={16} />,
                                        description: 'Organize tasks day by day',
                                        color: 'var(--vapor-primary)'
                                      },
                                      {
                                        id: 'board',
                                        name: 'Board',
                                        icon: <GridIcon size={16} />,
                                        description: 'Organize tasks in columns',
                                        color: 'var(--vapor-orange)'
                                      }
                                    ].map(c => ({
                                      id: c.id,
                                      type: 'item',
                                      component: (
                                        <Menu.Entity
                                          title={<p style={{ fontSize: 18 }}>{c.name}</p>}
                                          description={c.description}
                                          icon={
                                            <div
                                              style={{
                                                height: 36,
                                                width: 36,
                                                background: c.color,
                                                borderRadius: 50,
                                                color: 'white',
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center'
                                              }}
                                            >
                                              {c.icon}
                                            </div>
                                          }
                                        />
                                      )
                                    }))}
                                  >
                                    {({ attrs, ref }) => (
                                      <Button
                                        size="small"
                                        {...attrs}
                                        onClick={e => {
                                          e.preventDefault();
                                          e.stopPropagation();

                                          if (attrs.onClick) attrs.onClick(e);
                                        }}
                                        ref={ref}
                                        title="Create Calendar or Board"
                                        iconRight={<Plus />}
                                      >
                                        Create
                                      </Button>
                                    )}
                                  </Menu>
                                </VerticalCenter>
                              </div>
                            )}

                            <Spacer width={10} />

                            <div>
                              <VerticalCenter>
                                <Link href={`/team/${team.id}`}>
                                  <a>
                                    <Button
                                      size="small"
                                      title="Settings"
                                      icon={<Settings />}
                                    />
                                  </a>
                                </Link>
                              </VerticalCenter>
                            </div>

                            {team?.plan.id == 'free' && (
                              <>
                                <Spacer width={10} />

                                <div>
                                  <VerticalCenter>
                                    <Link href={`/team/${team.id}/subscription`}>
                                      <a>
                                        <Button size="small" as="div" variant="primary">
                                          Upgrade
                                        </Button>
                                      </a>
                                    </Link>
                                  </VerticalCenter>
                                </div>
                              </>
                            )}
                          </div>
                        </TeamHeader>

                        {team.disabled && (
                          <>Please upgrade to a paid plan to enable this team.</>
                        )}

                        {!team.disabled && team.calendars.length == 0 && (
                          <p>
                            You don't have any calendars yet. Get started by{' '}
                            <a
                              onClick={() => {
                                setCreateCalendarModalOpen(true);
                                setCreateCalendarType('calendar');
                                setCurrentTeamId(team.id);
                              }}
                            >
                              creating one
                            </a>
                            .
                          </p>
                        )}

                        <Calendars
                          style={team.disabled ? { opacity: 0.4, pointerEvents: 'none' } : {}}
                        >
                          {team.calendars.map(calendar => (
                            <a
                              key={calendar.id}
                              href={team.disabled ? undefined : `/view/${calendar.id}`}
                            >
                              <Calendar
                                hasAnalytics={team.plan.flags.activity}
                                calendar={calendar}
                                progress={progress[calendar.id]}
                                isStartOfWeek={isStartOfWeek}
                              />
                            </a>
                          ))}
                        </Calendars>
                      </section>
                    )
                )}
              </main>

              <Sidebar>
                <aside>
                  <div className="center">
                    <img
                      src={user?.photoUrl}
                      style={{ height: 150, width: 150, borderRadius: '50%' }}
                      alt={user?.name || 'Loading...'}
                    />
                  </div>

                  <Spacer height="20px" />

                  <div className="center">
                    <h1>{user?.name}</h1>
                  </div>

                  <Spacer height="5px" />

                  <div className="center">
                    <p>{user?.email}</p>
                  </div>

                  <Spacer height="20px" />

                  <div className="center">
                    <Link href="/account">
                      <a>
                        <Button variant="secondary" size="small" as="div">
                          Manage Account
                        </Button>
                      </a>
                    </Link>
                  </div>

                  <Spacer height="12px" />

                  <div className="center">
                    <Link href="/team/new">
                      <a>
                        <Button variant="primary" size="small" as="div">
                          Create Team
                        </Button>
                      </a>
                    </Link>
                  </div>
                </aside>
              </Sidebar>
            </Grid>

            <Modal.Wrapper
              isOpen={!!createCalendarModalOpen}
              onClose={() => setCreateCalendarModalOpen(false)}
              label={`Create ${createCalendarType == 'board' ? 'Board' : 'Calendar'}`}
              width={400}
              interactive
            >
              <form onSubmit={createCalendarForm.handleSubmit}>
                <Modal.Header>
                  Create {createCalendarType == 'board' ? 'Board' : 'Calendar'}
                </Modal.Header>

                <Modal.Content>
                  <Input
                    name="name"
                    label="Name"
                    placeholder={`Name your ${
                      createCalendarType == 'board' ? 'board' : 'calendar'
                    }`}
                    error={createCalendarForm.touched.name && createCalendarForm.errors.name}
                    {...createCalendarForm.getFieldProps('name')}
                  />
                </Modal.Content>

                <Modal.Actions>
                  <Button
                    variant="tertiary"
                    type="button"
                    onClick={() => setCreateCalendarModalOpen(false)}
                    disabled={creating}
                  >
                    Close
                  </Button>
                  <Button variant="primary" type="submit" loading={creating}>
                    Create
                  </Button>
                </Modal.Actions>
              </form>
            </Modal.Wrapper>
          </>
        )}
      </Inner>
    </Layout>
  );
}
