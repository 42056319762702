import { WeekriseLogo } from '@highloop-pkg/logo';
import { Button, Modal, Spacer, Text, VaporProvider } from '@vapor/ui';
import { styled } from 'buttered';
import ReactDOM from 'react-dom';

let Wrapper = styled('div')`
  padding: 90px 45px 45px 45px;
  text-align: center;

  @media screen and (max-width: 600px) {
    padding: 30px;
  }
`;

export let upgradeModal = ({ feature, teamId }: { feature: string; teamId: string }) => {
  let App = ({ onClose }) => (
    <VaporProvider>
      <Modal.Wrapper isOpen onClose={() => onClose()} label="Upgrade" width={520}>
        <Modal.Content>
          <Wrapper>
            <Modal.CloseButton />

            <WeekriseLogo size={70} />

            <Spacer height={30} />

            <Text as="h1" weight={600} size={32}>
              You found a pro feature.
            </Text>

            <Spacer height={26} />

            <Text as="h2" weight={500} size={22}>
              Upgrade to Weekrise Pro to unlock
              <br /> <mark>{feature}.</mark>
            </Text>

            <Spacer height={26} />

            <Text as="p" weight={500} size={20} style={{ opacity: 0.5 }}>
              Weekrise Pro includes: unlimited calendars and boards, a pomodoro timer, task comments, attachments, an activity log and up to 5 team members.
            </Text>

            <Spacer height={35} />

            <div>
              <a href={`/team/${teamId}/subscription`}>
                <Button variant="primary" size="big" onClick={() => onClose()} as="div">
                  Upgrade
                </Button>
              </a>
            </div>
          </Wrapper>
        </Modal.Content>
      </Modal.Wrapper>
    </VaporProvider>
  );

  let element = document.createElement('div');
  document.body.appendChild(element);

  ReactDOM.render(
    <App
      onClose={() => {
        ReactDOM.unmountComponentAtNode(element);
        document.body.removeChild(element);
      }}
    />,
    element
  );
};
